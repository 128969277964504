import styled from "styled-components";

import Sorting from "./Sorting";
import c from "./colors.json";

const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 0.5rem;
  margin-top: 6rem;
  color: ${c.color1};

  @media (max-width: 768px) {
    display: block;
    margin-top: 10px;
  }
`;

const SearchBar = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0px 0px 0px 10px;
  border-color: ${({ theme }) => theme.colors.alwaysWhite};
  border-width: 1px;
  border-radius: 0.5rem;
  transition: border-color 0.2s ease-out;
  &:focus-within {
    outline: none;
  }
`;

const SearchInput = styled.input`
  height: 38px;
  width: 350px;
  background: transparent;
  border-color: transparent;
  color: ${({ theme }) => theme.colors.alwaysWhite};
  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.alwaysWhite};
  }
  &::-webkit-input-placeholder {
    color: ${({ theme }) => theme.colors.alwaysWhite};
  }
  &:-moz-placeholder {
    color: ${({ theme }) => theme.colors.alwaysWhite};
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const SearchButton = styled.button`
  height: 38px;
  background-color: ${({ theme }) => theme.colors.alwaysWhite};
  color: ${({ theme }) =>
    theme.name === "dark" ? theme.colors.alwaysBlack : theme.colors.primary};
  border-color: transparent;
  padding: 0px 10px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  transition: color 0.2s ease-out;
  &:focus {
    outline: none;
  }
  // &:hover {
  //   color: ${({ theme }) =>
    theme.name === "dark"
      ? theme.colors.primaryLight
      : theme.colors.primaryDark};
  // }
`;

const RefreshButton = styled.button`
  height: 38px;
  background: transparent;
  border-color: ${c.color2};
  border-width: 1px;
  padding: 2px 10px;
  border-radius: 0.5rem;
  transition: border-color 0.2s ease-out, color 0.2s ease-out;
  color: ${({ theme }) => theme.colors.alwaysWhite};
  border-color: ${({ theme }) => theme.colors.alwaysWhite};
  &:focus {
    outline: none;
  }
  // &:active {
  //   border-color: ${({ theme }) => theme.colors.alwaysWhite};
  // }
  // &:hover {
  //   border-color: ${({ theme }) => theme.colors.alwaysWhite};
  // }
`;

interface Component {
  sortBy(e?: any): any;
  sortWith(e?: any): any;
  refresh(e?: any): any;
  onSearch(e?: any): any;
  onChangeSearch(e?: string): any;
  sortBySelected?: string;
  sortWithSelected?: string;
  searchValue?: string;
}

const Filters = ({
  refresh,
  onSearch,
  sortBy,
  sortWith,
  onChangeSearch,
  sortBySelected,
  sortWithSelected,
  searchValue,
}: Component) => {
  return (
    <FilterContainer>
      <SearchBar>
        <SearchInput
          placeholder="Enter token address to sell"
          value={searchValue}
          onChange={(e) => {
            onChangeSearch(e.target.value);
          }}
        />
        <SearchButton type="button" onClick={onSearch}>
          {/* <Icon name="chevron-down" iconSize={.8} /> */}
          Search
        </SearchButton>
      </SearchBar>

      <Sorting
        sortBy={sortBy}
        sortWith={sortWith}
        sortBySelected={sortBySelected}
        sortWithSelected={sortWithSelected}
      />

      <RefreshButton type="button" onClick={refresh}>
        Refresh
      </RefreshButton>
    </FilterContainer>
  );
};

export default Filters;
