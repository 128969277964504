import { useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { userOrderData } from "../../services/userOrder";
import { getUserOrdersFromLocalStorage } from "./myOrdersHelpers";
import { setUserOrders, setUserOrdersLoading } from "./myOrdersSlice";

const useMyOrders = () => {
  const dispatch = useAppDispatch();

  const { isActive, account, chainId } = useAppSelector((state) => state.web3);

  const [activeAccount, setActiveAccount] = useState<string>();
  const [activeChainId, setActiveChainId] = useState<number>();

  useEffect(() => {
    if (!account || !chainId) {
      return;
    }

    if (account === activeAccount && chainId === activeChainId) {
      return;
    }

    setActiveAccount(account);
    setActiveChainId(chainId);

    // const userOrders = getUserOrdersFromLocalStorage(account, chainId);
    // dispatch(setUserOrders(userOrders));

    const fetchData = async () => {
      try {
        const response = await userOrderData({
          userWalletAddress: account as string,
          chainId: chainId,
        });
        const _data: any[] = response?.data.map((item) => item?.order);
        dispatch(setUserOrders(_data));
      } catch (e) {
        console.log(e);
      }

      dispatch(setUserOrdersLoading(false));
    };

    fetchData();
  }, [account, chainId]);

  useEffect(() => {
    if (!isActive) {
      setActiveAccount(undefined);
      setActiveChainId(undefined);
    }
  }, [isActive]);
};

export default useMyOrders;
