import { useEffect, useState } from "react";

import styled from "styled-components";

import { useAppSelector } from "../../../app/hooks";
import { marketData } from "../../../services/market";
import Filters from "./subcomponents/Filters";
import Listing from "./subcomponents/Listing";

const Title = styled.div`
  margin-top: 6rem;
  @media (max-width: 768px) {
    margin-top: 10px;
  }
`;

const GridContainer = styled.div`
  width: 78%;
  margin-left: 0px;
  margin-top: 0px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px; /* Adjust gap between items */
  @media (max-width: 768px) {
    width: 100%;
    padding: 15px;
    grid-template-columns: 1fr; /* Change to a single column for mobile devices */
  }
`;

let page = 2;
const perPage = 500;

export default function MarketWidget() {
  const [listData, setListData] = useState<any[]>([]);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [sortBy, setSortBy] = useState<string>("recent");
  const [sortWith, setSortWith] = useState<string>("desc");
  const [searchValue, setSearchValue] = useState<any>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [loadMoreLoading, setLoadMoreLoading] = useState<boolean>(false);
  const { chainId, isInitialized } = useAppSelector((state) => state.web3);

  const fetchData = async ({ searchValue = "", page = 1 }) => {
    // if (!chainId) return;
    setListData([]);
    setIsLoading(true);
    try {
      const { data, pagination } = await marketData({
        page,
        perPage,
        searchValue,
        sortBy,
        sortWith,
        chainId: (chainId ? chainId : 369) as any,
      });

      setListData(data);
      setTotalItems(pagination?.total);
    } catch (e) {
      console.log(e);
    }
    setIsLoading(false);
  };

  // useEffect(() => {
  //   if (isInitialized) {
  //     fetchData({ searchValue, page: 1 });
  //   }
  // }, [sortBy, sortWith, chainId, isInitialized]);

  useEffect(() => {
    if (isInitialized) {
      const delayDebounceFn = setTimeout(() => {
        fetchData({ searchValue, page: 1 });
        return;
      }, 1000);
      return () => clearTimeout(delayDebounceFn);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy, sortWith, chainId, isInitialized]);

  const handleLoadMore = async () => {
    // setLoadMoreLoading(true);
    // try {
    //   const { data, pagination } = await marketData({
    //     page,
    //     perPage,
    //     searchValue,
    //     sortBy,
    //     sortWith,
    //   });
    //   setListData((state: any[]) => [...state, ...data]);
    //   setTotalItems(pagination?.total);
    //   page++;
    // } catch (e) {
    //   console.log(e);
    // }
    // setLoadMoreLoading(false);
  };

  const handleRefresh = () => {
    setSortBy("recent");
    setSortWith("desc");
    setSearchValue("");
    fetchData({ page: 1 });
    page = 2;
  };

  const handleSearch = () => {
    setSortBy("recent");
    setSortWith("desc");
    fetchData({ searchValue, page: 1 });
    page = 2;
  };

  return (
    <>
      <GridContainer>
        <Title>Marketplace</Title>
        <Filters
          sortBy={setSortBy}
          sortWith={setSortWith}
          sortBySelected={sortBy}
          sortWithSelected={sortWith}
          refresh={handleRefresh}
          searchValue={searchValue}
          onSearch={handleSearch}
          onChangeSearch={setSearchValue}
        />
      </GridContainer>
      <Listing
        limit={perPage}
        totalItems={totalItems}
        listData={listData}
        isLoading={isLoading}
        onLoadMore={handleLoadMore}
        paginateLoading={loadMoreLoading}
      />
    </>
  );
}
