import styled from "styled-components";

import { abbreviateString } from "../../../../helpers/abbreviateString";
import c from "./colors.json";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background: ${c.color2};
`;

interface Component {
  str?: string;
  height?: string | number;
  width?: string | number;
  fontSize?: string | number;
}

const AbbreviateToken = ({
  str = "",
  height = "3rem",
  width = "3rem",
  fontSize = "1.5rem",
}: Component) => {
  return (
    <Container style={{ height, width, fontSize }}>
      {abbreviateString(str)}
    </Container>
  );
};

export default AbbreviateToken;
