import { FC } from "react";

import MarketWidget from "../../components/@widgets/MarketWidget/MarketWidget";
import PageFullSize from "../../components/PageFullSize/Page";

const Market: FC = () => {
  return (
    <PageFullSize>
      <MarketWidget />
    </PageFullSize>
  );
};

export default Market;
