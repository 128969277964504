import { API_URL } from "../constants/environmentVariables";

export const marketData = async ({
  searchValue = "",
  sortBy = "recent",
  sortWith = "desc",
  page = 1,
  perPage = 10,
  chainId = "",
}) => {
  const offset = page * perPage - perPage;

  let _sortBy = "";

  switch (sortBy) {
    case "closing":
      _sortBy = "expiry";
      break;
    case "price":
      _sortBy = "SIGNER_AMOUNT";
      break;
    case "recent":
      _sortBy = "nonce";
      break;
  }

  const filterObject: any = {
    sortField: _sortBy,
    sortOrder: sortWith,
    offset: offset,
    limit: perPage,
  };

  if (String(searchValue).trim().length > 0)
    filterObject["senderTokens"] = [searchValue];

  const raw = JSON.stringify({
    jsonrpc: "2.0",
    id: "1",
    method: "getOrdersERC20",
    params: [filterObject],
  });

  const requestOptions: any = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: raw,
    redirect: "follow",
  };

  const response = await fetch(API_URL[chainId], requestOptions);
  const data = await response.json();

  const pagination = data?.result?.pagination;
  const numberOfPages = Math.ceil(pagination?.total / perPage);

  const _data: any[] = [];
  Object.entries(data?.result?.orders).map((item: any) => {
    _data.push(item[1]);
  });

  return {
    data: _data,
    pagination: { ...data?.result?.pagination, totalPages: numberOfPages },
  };
};
