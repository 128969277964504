import { useMemo, useState } from "react";
import { NavLink } from "react-router-dom";

import { compressFullOrderERC20 } from "@airswap/utils";
import { Web3Provider } from "@ethersproject/providers";
import { useWeb3React } from "@web3-react/core";

import { BigNumber } from "bignumber.js";
import styled, { keyframes } from "styled-components";

import useTakerInfo from "../../../../components/@widgets/OrderDetailWidget/hooks/useTakerInfo";
import { DEFAULT_TOKEN_IMAGE } from "../../../../constants/environmentVariables";
import nativeCurrency from "../../../../constants/nativeCurrency";
import { getExpiryTranslation } from "../../../../helpers/getExpiryTranslation";
import { getHumanReadableNumber } from "../../../../helpers/getHumanReadableNumber";
import { AppRoutes } from "../../../../routes";
import AbbreviateToken from "./AbbreviateToken";
import c from "./colors.json";

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) =>
    theme.name === "dark" ? theme.colors.darkGrey : theme.colors.primaryLight};
  color: ${({ theme }) =>
    theme.name === "dark" ? theme.colors.white : theme.colors.primary};
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
  gap: 0.8rem;
  transition: background-color 0.2s ease-out;
  &:hover {
    background-color: ${({ theme }) =>
      theme.name === "dark" ? "#181e2c" : "#dee3ea"};
  }
`;

const Button = styled(NavLink)`
  color: ${({ theme }) => theme.colors.alwaysWhite};
  background-color: ${({ theme }) =>
    theme.name === "dark" ? theme.colors.primaryDark : theme.colors.primary};
  padding: 5px 10px;
  border-radius: 10px;
  outline: none;
  transition: background-color 0.2s ease-out, color 0.2s ease-out;
  &:hover {
    background-color: ${c.color8};
    color: #000;
  }
  &:focus {
    outline: 0;
  }
  &:active {
    background-color: #03a115;
  }
`;

const Line = styled.hr`
  border: 1px solid;
  border-color: ${({ theme }) =>
    theme.name === "dark" ? theme.colors.grey : theme.colors.primary};
`;

const SingleFill = styled.span`
  font-family: tahoma;
  font-size: 11px;
  background: ${({ theme }) =>
    theme.name === "dark" ? theme.colors.primaryDark : theme.colors.primary};
  color: ${({ theme }) =>
    theme.name === "dark" ? theme.colors.white : theme.colors.alwaysWhite};
  padding: 1px 6px;
  text-transform: uppercase;
  border-radius: 5px;
`;

const shimmer = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const Shimmer = styled.div`
  background: #262d3d;
  background-size: 800px 100%;
  animation: ${shimmer} 1.5s ease-in-out infinite;
  height: ${"inherit"};
  width: ${"inherit"};
  opacity: 0;
  animation-delay: 1.5s;
  border-radius: 4px;
`;

interface component {
  data?: any;
}

export const getTokenAmountWithDecimals = (
  amount: string,
  decimals: number = 18
): BigNumber => {
  return new BigNumber(amount).div(10 ** decimals);
};
const ListingCard = ({ data }: component) => {
  const [imgError, setImgError] = useState(false);
  const [imgError1, setImgError1] = useState(false);

  const { chainId } = useWeb3React<Web3Provider>();

  const order: any = data?.order;

  const defaultTokenToAddress = nativeCurrency[chainId!]?.address;

  const [makerTokenInfo, makerTokenInfoLoading] = useTakerInfo(
    order?.signerToken || defaultTokenToAddress || null,
    order
  );
  const [takerTokenInfo, takerTokenInfoLoading] = useTakerInfo(
    order?.senderToken || defaultTokenToAddress || null,
    order
  );

  const senderAmount = useMemo(
    () =>
      getHumanReadableNumber(
        getTokenAmountWithDecimals(
          order?.senderAmount,
          takerTokenInfo?.decimals
        ).toString()
      ),
    [order, takerTokenInfo]
  );

  const timeLeft = useMemo(() => {
    const expiry = new Date(parseInt(order?.expiry) * 1000);
    return getExpiryTranslation(new Date(), expiry);
  }, [order]);

  const orderString = useMemo(() => compressFullOrderERC20(order), [order]);

  const signerAmount = useMemo(
    () =>
      getHumanReadableNumber(
        getTokenAmountWithDecimals(
          order?.signerAmount,
          makerTokenInfo?.decimals
        ).toString()
      ),
    [order, makerTokenInfo]
  );

  return (
    <CardContainer>
      {!makerTokenInfoLoading && !takerTokenInfoLoading ? (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{ display: "flex", alignItems: "center", gap: ".5rem" }}
            >
              <div
                style={{ position: "relative", height: "3rem", width: "3rem" }}
              >
                {makerTokenInfo?.symbol == "WPLS" ? (
                  <img
                    style={{
                      objectFit: "cover",
                      height: "3rem",
                      width: "3rem",
                      borderRadius: "100%",
                    }}
                    src={`images/networks/${takerTokenInfo?.chainId}.png`}
                    alt="image-logo"
                  />
                ) : (
                  <>
                    {makerTokenInfo?.logoURI && !imgError && (
                      <img
                        style={{
                          objectFit: "cover",
                          height: "3rem",
                          width: "3rem",
                          borderRadius: "100%",
                        }}
                        src={makerTokenInfo?.logoURI || DEFAULT_TOKEN_IMAGE}
                        alt="image-logo"
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          // currentTarget.src = "image_path_here";
                          setImgError(true);
                        }}
                      />
                    )}

                    {(!makerTokenInfo?.logoURI || imgError) && (
                      <AbbreviateToken str={makerTokenInfo?.name} />
                    )}
                  </>
                )}
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "3rem",
                }}
              >
                <span
                  style={{
                    fontSize: "1.2rem",
                    display: "flex",
                    alignItems: "center",
                    gap: 1.5,
                  }}
                >
                  <span style={{ display: "flex", gap: ".2rem" }}>
                    <span>{makerTokenInfo?.symbol}</span>
                    <span style={{ fontSize: "0.8rem" }}></span>
                  </span>
                </span>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      alignItems: "center",
                    }}
                  >
                    <SingleFill>single fill</SingleFill>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <span style={{ color: "#727783", fontSize: "11px" }}>OFFER</span>
              <span
                style={{ display: "flex", alignItems: "center", gap: ".3rem" }}
              >
                {signerAmount} {makerTokenInfo?.symbol}
                <div
                  style={{
                    position: "relative",
                    height: "1rem",
                    width: "1rem",
                  }}
                >
                  {makerTokenInfo?.symbol == "WPLS" ? (
                    <img
                      style={{
                        objectFit: "cover",
                        height: "1rem",
                        width: "1rem",
                        borderRadius: "100%",
                      }}
                      src={`images/networks/${takerTokenInfo?.chainId}.png`}
                      alt="image-logo"
                    />
                  ) : (
                    <>
                      {makerTokenInfo?.logoURI && !imgError && (
                        <img
                          style={{
                            objectFit: "cover",
                            height: "1rem",
                            width: "1rem",
                            borderRadius: "100%",
                          }}
                          src={makerTokenInfo?.logoURI || DEFAULT_TOKEN_IMAGE}
                          alt="image-logo"
                        />
                      )}

                      {(!makerTokenInfo?.logoURI || imgError) && (
                        <AbbreviateToken
                          str={makerTokenInfo?.name}
                          height={"1rem"}
                          width={"1rem"}
                          fontSize={"0.5rem"}
                        />
                      )}
                    </>
                  )}
                </div>
              </span>
              {/* <span style={{ color: '#727783', fontSize: '11px' }}>$0.000001/Token</span> */}
            </div>
            <span style={{ fontSize: "0.7rem" }}>
              <svg
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="white"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M5 0 L10 5 L5 10 z" />
              </svg>
            </span>
            <div
              style={{
                display: "flex",
                alignItems: "flex-end",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <span style={{ color: "#727783", fontSize: "11px" }}>FOR</span>
              <span
                style={{
                  color: "#907bea",
                  display: "flex",
                  alignItems: "center",
                  gap: ".3rem",
                }}
              >
                {senderAmount}
                <small>{takerTokenInfo?.symbol}</small>
                <div
                  style={{
                    position: "relative",
                    height: "1rem",
                    width: "1rem",
                  }}
                >
                  {takerTokenInfo?.symbol == "WPLS" ? (
                    <img
                      style={{
                        objectFit: "cover",
                        height: "1rem",
                        width: "1rem",
                        borderRadius: "100%",
                      }}
                      src={`images/networks/${takerTokenInfo?.chainId}.png`}
                      alt="image-logo"
                    />
                  ) : (
                    <>
                      {takerTokenInfo?.logoURI && !imgError1 && (
                        <img
                          style={{
                            objectFit: "cover",
                            height: "1rem",
                            width: "1rem",
                            borderRadius: "100%",
                          }}
                          src={takerTokenInfo?.logoURI || DEFAULT_TOKEN_IMAGE}
                          alt="image-logo"
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            // currentTarget.src = "image_path_here";
                            setImgError1(true);
                          }}
                        />
                      )}

                      {(!takerTokenInfo?.logoURI || imgError1) && (
                        <AbbreviateToken
                          str={takerTokenInfo?.name}
                          height={"1rem"}
                          width={"1rem"}
                          fontSize={"0.5rem"}
                        />
                      )}
                    </>
                  )}
                </div>
              </span>
              {/* <span style={{ color: '#727783', fontSize: '11px' }}>$100</span> */}
            </div>
          </div>

          <Line />

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <span style={{ color: "#727783", fontSize: "11px" }}>
              Closing in {timeLeft}
            </span>
            <Button to={`/${AppRoutes.order}/${orderString}`}>Buy</Button>
          </div>
        </>
      ) : (
        <div style={{ height: "160px", width: "100%" }}>
          <Shimmer />
        </div>
      )}
    </CardContainer>
  );
};

export default ListingCard;
