import { useEffect, useState } from "react";

import styled from "styled-components";

import ListingCard from "./ListingCard";
import c from "./colors.json";

const common = {
  width: "88%",
  "margin-left": "9%",
  "margin-top": "2rem",
};

const MessageContainer = styled.div`
  width: ${common.width};
  margin-left: ${common["margin-left"]};
  margin-top: ${common["margin-top"]};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const GridContainer = styled.div`
  width: ${common.width};
  margin-left: ${common["margin-left"]};
  margin-top: ${common["margin-top"]};
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px; /* Adjust gap between items */
  padding-bottom: 70px;
  @media (max-width: 768px) {
    width: 100%;
    margin: 0px;
    padding: 15px;
    grid-template-columns: 1fr; /* Change to a single column for mobile devices */
  }
`;

const LoadMoreButton = styled.button`
  margin: 30px 0px;
  height: 45px;
  background: transparent;
  border-color: transparent;
  border-color: ${c.color2};
  border-width: 1px;
  padding: 10px 20px;
  border-radius: 0.5rem;
  transition: border-color 0.2s ease-out, color 0.2s ease-out;
  &:focus {
    outline: none;
  }
  &:active {
    border-color: ${c.color3};
  }
  &:hover {
    color: ${c.color5};
  }
`;

interface component {
  limit?: number;
  totalItems?: number;
  listData?: any[];
  isLoading?: boolean;
  onLoadMore: () => any;
  paginateLoading?: boolean;
}

const Listing = ({
  listData = [],
  isLoading,
  paginateLoading,
  onLoadMore,
  totalItems = 0,
  limit = 0,
}: component) => {
  const [cardIndex, setCardIndex] = useState(0);

  useEffect(() => {
    setCardIndex(0);
    if (listData?.length > 0) {
      const intervalId = setInterval(() => {
        setCardIndex((prevIndex) => {
          const _count = Math.min(prevIndex + 1, listData?.length - 1);
          return _count;
        });
      }, 80);

      return () => clearInterval(intervalId);
    }
  }, [listData]);

  return (
    <>
      {isLoading && <MessageContainer>Loading...</MessageContainer>}
      {!isLoading && listData.length === 0 && (
        <MessageContainer>No results.</MessageContainer>
      )}

      {!isLoading && listData.length > 0 && (
        <>
          <GridContainer>
            {listData
              ?.slice(0, cardIndex + 1)
              ?.map((item: any, index: number) => (
                <ListingCard key={index} data={item} />
              ))}
          </GridContainer>

          {/* {(listData.length !== totalItems)
                        &&
                        <div style={{
                            width: common.width,
                            marginLeft: common["margin-left"],
                            marginTop: common["margin-top"],
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>
                            <LoadMoreButton type="button" onClick={onLoadMore} disabled={paginateLoading || isLoading}>
                                {paginateLoading ? 'Loading...' : 'Load More'}
                            </LoadMoreButton>
                        </div>
                    } */}
        </>
      )}
    </>
  );
};

export default Listing;
