import { useCallback, useEffect, useRef, useState } from "react";

import styled from "styled-components";

import Icon from "../../../../components/Icon/Icon";
import c from "./colors.json";

const Container = styled.div`
  position: relative;
`;

const SortingButton = styled.button`
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  background: transparent;
  color: ${({ theme }) => theme.colors.alwaysWhite};
  border-color: ${({ theme }) => theme.colors.alwaysWhite};
  min-width: 210px;
  border-width: 1px;
  padding: 2px 10px;
  border-radius: 0.5rem;
  transition: border-color 0.2s ease-out, color 0.2s ease-out;
  &:focus {
    outline: none;
  }
  // &:active {
  //   border-color: ${c.color3};
  // }
  // &:hover {
  //   color: ${c.color5};
  // }
  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 5px;

    margin-top: 5px;
  }
`;

const Dropdown = styled.div`
  position: absolute;
  top: 120%;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  min-width: 210px;
  background-color: ${({ theme }) =>
    theme.name === "dark" ? theme.colors.darkGrey : theme.colors.primaryLight};
  color: ${({ theme }) =>
    theme.name === "dark" ? theme.colors.white : theme.colors.primary};
  border-color: ${({ theme }) =>
    theme.name === "dark" ? theme.colors.grey : theme.colors.primary};
  border-width: 1px;
  padding: 2px 10px;
  border-radius: 0.5rem;
  z-index: 99999;
`;

const Line = styled.hr`
  width: 100%;
  border: 1px solid;
  border-color: ${({ theme }) =>
    theme.name === "dark" ? theme.colors.grey : theme.colors.primary};
`;

const SortSelectButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  background: transparent;
  transition: color 0.2s ease-out;
  &:focus {
    outline: none;
  }
  &:hover {
    color: ${({ theme }) =>
      theme.name === "dark"
        ? theme.colors.primaryLight
        : theme.colors.primaryDark};
  }
  ${({ selected, theme }: { selected: any; theme: any }) =>
    selected &&
    `
     color: ${
       theme.name === "dark"
         ? theme.colors.primaryLight
         : theme.colors.primaryDark
     };
  `}
`;

interface Component {
  sortBy(e?: any): any;
  sortWith(e?: any): any;
  sortBySelected?: string;
  sortWithSelected?: string;
}

const Sorting = ({
  sortBy,
  sortWith,
  sortBySelected = "desc",
  sortWithSelected = "closing",
}: Component) => {
  const [showDropDown, setShowDropDown] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const popoverRef = useRef<HTMLDivElement>(null);

  const handleClick = useCallback((e) => {
    if (
      (containerRef.current && containerRef.current.contains(e.target)) ||
      (popoverRef.current && popoverRef.current.contains(e.target))
    ) {
      return;
    }
    setShowDropDown(false);
  }, []);

  const handleEscKey = useCallback((e) => {
    if (e.keyCode === 27) {
      setShowDropDown(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    document.addEventListener("keydown", handleEscKey);

    return () => {
      document.removeEventListener("mousedown", handleClick);
      document.removeEventListener("keydown", handleEscKey);
    };
  }, []);

  const handleSortBy = (value: string) => {
    sortBy(value);
  };

  const handleSortWith = (value: string) => {
    sortWith(value);
  };

  return (
    <Container>
      <SortingButton
        type="button"
        onClick={() => {
          setShowDropDown((state: boolean) => !state);
        }}
      >
        <span
          style={{
            textTransform: "capitalize",
            display: "flex",
            alignItems: "center",
            gap: "0.3rem",
          }}
        >
          <Icon name="swap" iconSize={0.8} />
          {sortBySelected}:{" "}
          {sortWithSelected === "asc" ? "Ascending" : "Descending"}
        </span>
        <Icon name="chevron-down" iconSize={1.2} />
      </SortingButton>

      {showDropDown && (
        <Dropdown ref={popoverRef}>
          <div
            style={{
              width: "100%",
              paddingTop: "10px",
              display: "flex",
              flexDirection: "column",
              gap: "0.4rem",
            }}
          >
            <SortSelectButton
              selected={sortBySelected === "closing"}
              onClick={() => {
                handleSortBy("closing");
              }}
            >
              Closing{" "}
              {sortBySelected === "closing" && (
                <Icon name="check" iconSize={1} />
              )}
            </SortSelectButton>
            <SortSelectButton
              selected={sortBySelected === "price"}
              onClick={() => {
                handleSortBy("price");
              }}
            >
              Price{" "}
              {sortBySelected === "price" && <Icon name="check" iconSize={1} />}
            </SortSelectButton>
            <SortSelectButton
              selected={sortBySelected === "recent"}
              onClick={() => {
                handleSortBy("recent");
              }}
            >
              Recent{" "}
              {sortBySelected === "recent" && (
                <Icon name="check" iconSize={1} />
              )}
            </SortSelectButton>
          </div>
          <Line />
          <div
            style={{
              width: "100%",
              paddingBottom: "10px",
              display: "flex",
              flexDirection: "column",
              gap: "0.4rem",
            }}
          >
            <SortSelectButton
              selected={sortWithSelected === "asc"}
              onClick={() => {
                handleSortWith("asc");
              }}
            >
              <span
                style={{ display: "flex", alignItems: "center", gap: "0.3rem" }}
              >
                <span style={{ transform: "rotate(180deg)" }}>
                  <Icon name="arrow-down" iconSize={1} />
                </span>
                Sort Ascending
              </span>
              {sortWithSelected === "asc" && <Icon name="check" iconSize={1} />}
            </SortSelectButton>
            <SortSelectButton
              selected={sortWithSelected === "desc"}
              onClick={() => {
                handleSortWith("desc");
              }}
            >
              <span
                style={{ display: "flex", alignItems: "center", gap: "0.3rem" }}
              >
                <Icon name="arrow-down" iconSize={1} />
                Sort Descending
              </span>
              {sortWithSelected === "desc" && (
                <Icon name="check" iconSize={1} />
              )}
            </SortSelectButton>
          </div>
        </Dropdown>
      )}
    </Container>
  );
};

export default Sorting;
