export function abbreviateString(str: string = "") {
  const trimmedStr = str.trim();

  if (trimmedStr.length === 0) return "";

  // Check if there are spaces
  const hasSpaces = trimmedStr.includes(" ");

  // Handle strings with spaces (like "John Doe")
  if (hasSpaces) {
    const words = str?.split(" ");
    return words
      ?.map((word) => word[0]?.toUpperCase())
      ?.join("")
      ?.slice(0, 2);
  } else {
    // Handle camelCase or single words (like "RewardToken")
    return trimmedStr
      ?.split(/(?=[A-Z])/)
      ?.map((word) => word[0]?.toUpperCase())
      ?.join("")
      ?.slice(0, 2);
  }
}
