type ApiUrlOptions = {
  [key: string | number]: string;
};

export const API_URL: ApiUrlOptions = {
  369: "https://orders.9mm.pro",
  8453: "https://base-orders.9mm.pro",
};

export const DEFAULT_TOKEN_IMAGE: string = "/images/default_token.png";
